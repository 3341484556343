import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

export const Head = () => <Seo title="A propos" noindex />

const AboutPage = () => (
  <Layout content="page">
    <h1>A propos</h1>
    <h2>My tour of Europe</h2>
    <p>
      My tour of Europe présente des conseils pour voyager en Europe, préparer
      un tour d’Europe ou partir en week-end en Europe.
    </p>
    <h2>Joachim</h2>
    <p>
      Au quotidien, je développe des sites internet et des applications mobiles
      en freelance sous le nom{" "}
      <a
        title="Solygambas"
        href="https://bit.ly/solygambas"
        target="_blank"
        rel="noopener noreferrer"
      >
        Solygambas
      </a>
      .
    </p>
    <h2>Vous appréciez My tour of Europe ?</h2>
    <p>
      Pour nous écrire, merci d’utiliser la{" "}
      <Link to="/contact" title="Contact">
        page contact
      </Link>
      .
    </p>
    <p>
      <Link to="/" title="My tour of Europe">
        Revenir à l'accueil
      </Link>
    </p>
  </Layout>
)

export default AboutPage
